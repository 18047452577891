import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { muiTheme } from "../../lib/theme"

const ExpansionPanelWrapp = styled.div`
  margin-bottom: 35px;

  ${muiTheme.breakpoints.down("md")} {
    margin-bottom: 20px;
  }
`
const Otazka = styled.h3``

interface Props {}
const ExpansionPanels: React.FC<Props> = (props, index) => {
  const {
    strapiFaq: { otazka: questions },
  } = useStaticQuery(graphql`
    query faq {
      strapiFaq {
        otazka {
          Odpoved
          Otazka
        }
      }
    }
  `)

  return (
    <div>
      {questions.map((item, index) => (
        <ExpansionPanelWrapp
          key={index}
          data-sal-duration="700"
          data-sal="slide-up"
          data-sal-delay={index * 50}
          data-sal-easing="ease"
        >
          <Accordion style={{ boxShadow: "5px 5px 20px rgba(0, 0, 0, 0.19)" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#3F499F", fontSize: "32px" }} />}
              aria-controls="panel1a-content"
              id="{index}"
            >
              <Otazka>{item.Otazka} </Otazka>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ padding: "5px 0 5px 25px" }} variant="h3">
                <p> {item.Odpoved}</p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ExpansionPanelWrapp>
      ))}
    </div>
  )
}

export default ExpansionPanels
