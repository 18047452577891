import React from "react"
import Layout from "../components/shared/Layout/layout"
import SEO from "../components/seo"
import Container from "../components/shared/Layout/components/Container/container"
import styled from "styled-components"
import { Section } from "../components/shared/Layout/components/Section/Section"
import ExpansionPanels from "../components/Faq/ExpansionPanels"
import { Grid } from "@material-ui/core"
import { PageHeader } from "../components/shared/PageHeader/PageHeader"
const FaqSection = styled(Section)``
const FaqPage = () => (
  <Layout fluid={true}>
    <SEO
      title="Často kladené otázky"
      description="Často kladené otázky v autoškole Hron a odpovede na ne."
    />
    <FaqSection>
      <Container fluid={false}>
        <PageHeader header={"Často kladené otázky"} />
        <Grid>
          <ExpansionPanels />
        </Grid>
      </Container>
    </FaqSection>
  </Layout>
)

export default FaqPage
